import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const ZipFormBannerStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-form {
        input[name='zip_postal'] {
          min-width: 100px;
        }
      }
    `}
  />
))

export default ZipFormBannerStyles
