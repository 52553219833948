import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const ChatStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      @media screen and (max-width: ${theme.breakpoints.md}) {
        body.lp-minimal-chat {
          .Twilio-EntryPoint {
            bottom: 12px;
            right: 15px;

            .Twilio-EntryPoint-Tagline {
              display: none;
            }
          }
        }
      }
    `}
  />
))

export default ChatStyles
