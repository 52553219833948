const merge = require('lodash.merge')

// Import the brand level theme so it can be merged.
const brandTheme = require('gatsby-theme-centurylink/theme')

// Merge the brand level theme and the site level theme.
// The second object being passed to the merge function
// is the site level theme. Anything placed here will
// override the brand level theme.
module.exports = merge(brandTheme, {
  logos: {
    chat: 'logo-chat.svg',
  },
})
