exports.components = {
  "component---src-templates-brightspeed-city-jsx": () => import("./../../../src/templates/BrightspeedCity.jsx" /* webpackChunkName: "component---src-templates-brightspeed-city-jsx" */),
  "component---src-templates-brightspeed-state-jsx": () => import("./../../../src/templates/BrightspeedState.jsx" /* webpackChunkName: "component---src-templates-brightspeed-state-jsx" */),
  "component---src-templates-city-jsx": () => import("./../../../src/templates/City.jsx" /* webpackChunkName: "component---src-templates-city-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/Default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-dynamic-zip-packages-jsx": () => import("./../../../src/templates/DynamicZipPackages.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-jsx" */),
  "component---src-templates-dynamic-zip-packages-spanish-jsx": () => import("./../../../src/templates/DynamicZipPackagesSpanish.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-spanish-jsx" */),
  "component---src-templates-lp-minimal-chat-jsx": () => import("./../../../src/templates/LPMinimalChat.jsx" /* webpackChunkName: "component---src-templates-lp-minimal-chat-jsx" */),
  "component---src-templates-one-trust-privacy-policy-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-jsx" */),
  "component---src-templates-state-jsx": () => import("./../../../src/templates/State.jsx" /* webpackChunkName: "component---src-templates-state-jsx" */)
}

